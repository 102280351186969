<template>
  <div class="login">
    <div class="middle-wrapper">
      <div class="login-form">
        <div class="title-wrapper">
          <div class="title">蓝英科技后台管理</div>
        </div>
        <el-form ref="loginForm" :model="form" :rules="rules">
          <el-form-item prop="loginName">
            <el-input v-model="form.loginName" placeholder="请输入账号">
<!--              <i-->
<!--                slot="prefix"-->
<!--                class="el-input__icon el-icon-adm-user"-->
<!--                style="font-size: 18px;"-->
<!--              ></i>-->
              <i
                slot="prefix"
                class="el-input__icon el-icon-s-custom"
                style="font-size: 18px;"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :type="passwordType"
              v-model="form.password"
              placeholder="请输入密码"
            >
<!--              <i-->
<!--                slot="prefix"-->
<!--                class="el-input__icon el-icon-adm-password"-->
<!--                style="font-size: 18px;"-->
<!--              ></i>-->
              <i
                slot="prefix"
                class="el-input__icon el-icon-key"
                style="font-size: 18px;"
              ></i>
              <i
                slot="suffix"
                class="el-input__icon el-icon-view"
                style="cursor: pointer;"
                @click="_togglePasswordType"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="yanzhengma">
            <div class="check-code-wrapper">
              <div class="yanzhengma-wrapper">
                <el-input
                  v-model="form.yanzhengma"
                  @keyup.enter.native="login('loginForm')"
                  placeholder="请输入验证码"
                >
<!--                  <i-->
<!--                    slot="prefix"-->
<!--                    class="el-input__icon el-icon-adm-vertification"-->
<!--                    style="font-size: 18px;"-->
<!--                  ></i>-->
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-lock"
                    style="font-size: 18px;"
                  ></i>
                </el-input>
              </div>
              <div class="validate-code-wrapper">
                <validate-code
                  ref="validate-code"
                  @change="_setCheckCode"
                ></validate-code>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="margin-bottom: 0;">
            <el-col :span="10" :offset="14">
              <el-form-item>
                <el-button
                  type="primary"
                  class="login-btn"
                  style="width: 100%;"
                  @click="loginHandle('loginForm')"
                >
                  登陆系统
                </el-button>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import validateCode from '@/components/ValidateCode/index'
import{sysLogin} from '@/api/login'

export default {
  created() {},
  data() {
    var checkYanzhengma = (rule, value, callback) => {
      value = value.toUpperCase()
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else if (value !== this.checkCode) {
        callback(new Error('验证码错误'))
        this.$refs['validate-code'].draw()
      } else {
        callback()
      }
    }
    return {
      passwordType: 'password',
      checkCode: '',
      form: {
        loginName: '',
        password: '',
        yanzhengma: ''
      },
      rules: {
        loginName: [
          { required: true, message: '账号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        yanzhengma: [{ validator: checkYanzhengma, trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.$store.commit('SET_TOKEN', null)
  },
  methods: {
    _setCheckCode(value) {
      this.checkCode = value
    },
    _togglePasswordType() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    },
    loginHandle(formName) {
      this.$router.push({ path: '/homePageSettings' })
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.login()
        }
      })
    },
    login() {
      /*
       *  在这边可以进行登陆请求
       *  将请求返回的Token对象存到store中
       *  @Token  token对象
       */
      sysLogin(this.form).then((res) => {
        console.log(res);
        if (res.message != 'success') {
          // this.$confirm(res.info, '信息提示', {
          //   confirmButtonText: '确定',
          //   type: 'warning'
          // })
          this.$message.error(res.result)
          console.log('fail')
          // this.$store.commit('SET_TOKEN', null)
          return
        }
        console.log('success')
        let token = res.token
        this.$store.commit('SET_TOKEN', token)
        this.$router.replace('/main/home')
      })
      // this.$router.replace('home')
    }
  },
  components: {
    validateCode
  }
}
</script>
<style lang="less" scoped>
.login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //background-color: rgb(32, 160, 255);
  //background: url("../../assets/loginBackgroundImg.jpg");
  background-color: #fff;
  background: url("../../assets/loginBackgroundImg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;

  .middle-wrapper {
    position: fixed;
    width: 100%;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-60%);

    .title-wrapper {
      //margin-top: 50px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #767777;
    }
    .login-form {
      position: relative;
      margin: 0 auto;
      width: 480px;
      padding: 30px 50px;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: #ffffff;

      .el-row {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .login-btn {
          width: 100%;
        }
      }

      .check-code-wrapper {
        display: flex;
        justify-content: space-between;
        height: 40px;

        .yanzhengma-wrapper {
          flex: 0 1 auto;
        }

        .validate-code-wrapper {
          flex: 0 0 160px;
        }
      }
    }
  }
}
</style>
